/*

Hydro Template 

http://www.absk.com/tm-509-hydro

*/
@import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600');
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600');
@import url('https://fonts.googleapis.com/css2?family=Audiowide&display=swap');

:root {

  --gold-image-0: url(./assets/images/gold-background.jpg);
  --gold-image-1: url(./assets/images/gold-background-1.jpg);
  --gradient-0: linear-gradient(45deg, #bd1598, #0c94b6);
  /* --gold-gradient: linear-gradient(315deg, #baba00 0%, #e49502 74%); */
  --gold-gradient-linear: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #e4e272 0%, #87874a 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%);
  /* --gold-gradient: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #e4e272 0%, #87874a 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%); */
  /* --gold-gradient-highlight: linear-gradient(315deg, #000000 0%, #352d01 74%); */
  --gold-gradient: url(./assets/images/gold-background-1b.jpg);
  --gold-gradient-highlight: url(./assets/images/gold-background-1.jpg);
  /* --gold-gradient:url(./assets/images/african-bg2.jpg); */
  --africa-gradient: url(./assets/images/african-bg2.jpg);

}

body {
  background: #ffffff;
  font-family: "Fira Sans", sans-serif;
  overflow-x: hidden;
}


/*---------------------------------------
     TYPOGRAPHY              
  -----------------------------------------*/

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
  line-height: inherit;
  letter-spacing: -1px;
}

h1 {
  color: transparent;
  font-size: 4.5em;
  margin-bottom: 30px;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  line-height: 1em;
}

h2 {
  color: #393939;
  font-size: 2em;
}

h3 {
  color: #505050;
  font-size: 1.5em;
  font-weight: 500;
  margin-bottom: 0;
}

h4 {
  color: #696969;
  font-size: 18px;
  line-height: normal;
}

p {
  color: #757575;
  font-size: 14px;
  font-weight: normal;
  line-height: 25px;
}

strong {
  font-weight: 500;
}


/*---------------------------------------
     GENERAL               
  -----------------------------------------*/

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

a {
  color: #252525;
  font-weight: normal;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  text-decoration: none !important;
}

a:hover,
a:active,
a:focus {
  color: #ce3232;
  outline: none;
}

.section-title {
  margin-bottom: 60px;
}

.section-title h2 {
  margin-top: 0;
  margin-bottom: 10px;
  line-height: 0;
}

.section-title .line-bar {
  color: #d9d9d9;
  display: block;
  font-size: 5em;
  line-height: 0;
}

.home-info .section-btn {
  letter-spacing: 3px !important;

}

.section-btn {
  background: var(--gradient-0);
  border: 0;
  border-radius: 100px;
  color: #ffffff;
  cursor: pointer;
  font-size: inherit;
  font-weight: normal;
  padding: 15px 30px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-family: "Montserrat", arial, sans-serif;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
}

.section-btn:hover,
.section-btn:focus {
  background: #00F260;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #0575E6, #00F260);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #0575E6, #00F260);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  border-color: transparent;
  color: #393939;
}

.overlay {
  background: #fdfffee3;
  /* fallback for old browsers */
  /* background: -webkit-linear-gradient(to right, #0575E6, #020e07); */
  /* Chrome 10-25, Safari 5.1-6 */
  /* background: linear-gradient(to right, #051f3a, #020e07); */
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  /* opacity: 0.7; */
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
}

section {
  padding-top: 80px;
  padding-bottom: 80px;
  text-align: center;
}


/*---------------------------------------
       PRE LOADER              
  -----------------------------------------*/

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background: none repeat scroll 0 0 #ffffff;
}

.spinner {
  border: 1px solid transparent;
  border-radius: 3px;
  position: relative;
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 45px;
  height: 45px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 1px solid #575757;
  border-top-color: #ffffff;
  animation: spinner .9s linear infinite;
}

/* 
@-webkit-@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
} */

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}


/*---------------------------------------
      MENU              
  -----------------------------------------*/

.custom-navbar {
  border: none;
  margin-bottom: 0;
  padding: 25px 0;
  padding-top: 10px;
}

/* .logo-img {
  height: 40px;
  width:40px;
  background-image: url(./assets/images/logo.png);
  background-size: cover;
  margin-right:10px
} */

.custom-navbar .navbar-brand {
  color: #000000;
  font-weight: 500;
  letter-spacing: -1px;
}

.navbar-brand span {
  font-family: 'Audiowide', cursive;
  font-size: 40px;
  /* line-height: 40px; */
}

.top-nav-collapse {
  background: #ffffff;
}

.custom-navbar .navbar-nav.navbar-nav-first {
  margin-left: 8em;
}

.custom-navbar .navbar-nav.navbar-right li a {
  padding-right: 18px;
  padding-left: 18px;
}

.custom-navbar .section-btn {
  padding: 15px;
  margin-left: 2em;
}

.custom-navbar .section-btn:hover {
  background: #000000;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #434343, #000000);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #434343, #000000);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.custom-navbar .section-btn a {
  padding: 0;
}

.custom-navbar .nav .section-btn a:hover {
  color: #ffffff;
}

.custom-navbar .nav li a {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  letter-spacing: -0.5px;
  padding-right: 22px;
  padding-left: 22px;
}

.custom-navbar .nav li a:hover {
  background: transparent;
  color: #ce3232;
}

.custom-navbar .navbar-nav>li>a:hover,
.custom-navbar .navbar-nav>li>a:focus {
  background-color: transparent;
}

.custom-navbar .nav li.active>a {
  background-color: transparent;
  color: #a3870a;
}

.custom-navbar .navbar-toggle {
  border: none;
  padding-top: 10px;
}

.custom-navbar .navbar-toggle {
  background-color: transparent;
}

.custom-navbar .navbar-toggle .icon-bar {
  background: #252525;
  border-color: transparent;
}

@media(min-width:768px) {
  .custom-navbar {
    border-bottom: 0;
    background: 0 0;
  }

  .custom-navbar.top-nav-collapse {
    background: #ffffff;
    -webkit-box-shadow: 0 1px 30px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 1px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 30px rgba(0, 0, 0, 0.1);
    padding: 12px 0;
  }

  .top-nav-collapse .navbar-brand {
    color: #252525;
  }

  .top-nav-collapse .nav li a {
    color: #575757;
  }

  .top-nav-collapse .nav .section-btn a {
    color: #ffffff;
  }
}



/*---------------------------------------
      HOME              
  -----------------------------------------*/

#home {
  /* background: url('./assets/images/4.jpg') no-repeat center center; */
  /* background-image: url(./assets/images/background_1.PNG); */
  background-size: cover;
  vertical-align: middle;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  padding-top: 10em;
  text-align: left;

}

#home h1 {
  color: #ffffff;
}

.home-info .section-btn {
  background: var(--gold-gradient);
}

.african-but {
  background: var(--africa-gradient) !important;
  background-position: center;
  transition: .5s;
}

.african-but:hover {
  color: white !important;
  border: 1px solid #eeeeee00;
}

.home-info .transparent-btn {
  background: transparent;
  border: 2px solid #cfc8c8;
  color: #221a1a;
}

.home-info .left-button {
  margin-right: 15px;
}

.home-info .section-btn:hover,
.home-info .section-btn:focus {
  background: #ce3232;
  border-color: transparent;
  color: #ffffff;
}

.home-info span {
  display: inline-block;
  vertical-align: middle;
  color: #f9f9f9;
  font-weight: 500;
  margin-left: 20px;
}

.home-info span small {
  display: block;
}



/*---------------------------------------
      ABOUT              
  -----------------------------------------*/

#courses {
  text-align: left;
  padding-bottom: 0;
  color: #000000;
}



.sect-title {
  font-size: 30px;
  line-height: 40px;
  color: black;
  text-transform: capitalize;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: 100;
}

.sect-sub-title {
  font-size: 20px;
  line-height: 40px;
  color: black;
  /* text-transform: capitalize; */
  font-family: "Montserrat", sans-serif;
  font-weight: 100;
}

#counselling {
  text-align: center;
  padding-bottom: 20px;
  background: #f2f2f2
}

.counselling-list {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-bottom: 50px;
  column-gap: 20px;
}

.counselling-item {
  background-color: #fff;
  box-shadow: -3px 6px 28px 4px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: -3px 6px 28px 4px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: -3px 6px 28px 4px rgba(0, 0, 0, 0.3);
  width: 260px;
  height: 260px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  padding-top: 20px;
  position: relative;
}

.counselling-item-icon {
  height: 100px;
  width: 100px;
  background-size: cover;
}

.emergency-img {
  background-image: url(./assets/images/emergency.png);
}

.routine-img {
  background-image: url(./assets/images/routine.png);
}

.special-img {
  background-image: url(./assets/images/special.png);
}


.counselling-item-body {
  padding-top: 20px;
}

.counselling-item-description {
  height: 40px;
  font-weight: 700;
}

.counselling-free-label {
  font-weight: 700;
  position: absolute;
  top: -10px;
  left: 20px;
  background-color: #00F260;
  color: white;
  padding: 10px;
  box-shadow: -1px 7px 20px 0px rgba(0, 0, 0, 0.45);
  -webkit-box-shadow: -1px 7px 20px 0px rgba(0, 0, 0, 0.45);
  -moz-box-shadow: -1px 7px 20px 0px rgba(0, 0, 0, 0.45);
}

/*---------------------------------------
      WORK             
  -----------------------------------------*/

.work-info img {
  width: 100%;
  border-radius: 100%;
}

#work .work-thumb {
  overflow: hidden;
  position: relative;
  cursor: pointer;
  border-radius: 3px;
}

.work-thumb .work-info {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.work-thumb .work-info:after {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  content: "";
  background: #ce3232;
  opacity: 0;
  transition: 0.5s;
}

.work-thumb .work-info:hover::after {
  opacity: 0.9;
}

.work-thumb .work-info h3,
.work-thumb .work-info small {
  transform: translateY(100%);
  opacity: 0;
  display: block;
  transition: 0.5s 0.2s;
  color: #ffffff;
  z-index: 2;
  position: relative;
}

.work-thumb .work-info small {
  color: #d9d9d9;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  margin-top: 3px;
}

.work-thumb:hover .work-info h3,
.work-thumb:hover .work-info small {
  transform: translateY(0px);
  opacity: 1;
}



/*---------------------------------------
      BLOG             
  -----------------------------------------*/

#blog {
  background: #f0f0f0;
}

#blog-header,
#blog-detail {
  text-align: left;
}

#blog-header {
  /* background: url('./assets/images/blog-header-bg.jpg') no-repeat center center; */
  background-size: cover;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 65vh;
  color: #ffffff;
  position: relative;
  padding-top: 12em;
}

#blog-header h2 {
  color: #ffffff;
}

.blog-thumb {
  background: #ffffff;
  -webkit-box-shadow: 0 1px 30px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 30px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  text-align: left;
  margin-bottom: 30px;
  height: 350px;
}

.media.blog-thumb .media-left {
  width: 45%;
}

.blog-thumb small {
  color: #ce3232;
  font-weight: 500;
  display: block;
}

.blog-thumb small .fa {
  margin-right: 5px;
}

.blog-thumb h4 {
  margin-top: 2px;
  margin-bottom: 0px;
}

#blog-detail h2 {
  padding: 25px 0 10px 0;
}

.blog-info {
  padding: 75px 25px;
}

.blog-info h3 {
  margin: 0;
  padding: 8px 0 6px 0;
  text-transform: capitalize;
}

.blog-info .section-btn {
  background: #f0f0f0;
  color: #393939;
  margin-top: 20px;
}

.blog-info .section-btn:hover {
  background: #00F260;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #0575E6, #00F260);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #0575E6, #00F260);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: #ffffff;
}

.blog-thumb ul {
  margin: 32px 12px 22px 0px;
}

.blog-thumb ul li {
  list-style: square;
  font-weight: normal;
  padding: 4px 12px 4px 0px;
}

.blog-social-share {
  text-align: center;
  padding-top: 22px;
}

.blog-social-share .btn {
  border-radius: 100px;
  border: none;
  font-size: 10px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin: 20px 6px;
  padding: 10px 16px;
}

.blog-social-share .btn-primary {
  background: #3b5998;
}

.blog-social-share .btn-success {
  background: #1da1f2;
}

.blog-social-share .btn-danger {
  background: #dd4b39;
}

.blog-social-share a .fa {
  padding-right: 4px;
}

.blog-ads {
  background: #f9f9f9;
  border-right: 4px solid #2b2b2b;
  padding: 42px;
  text-align: center;
  margin: 26px 0 26px 0;
}

.blog-ads h4 {
  font-size: 18px;
}



// /*---------------------------------------
//       CONTACTS             
//   -----------------------------------------*/

// #contact {
//   background: #00F260;
//   background: var(--gold-gradient);
//   /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
// }

// #contact .section-title h2 {
//   color: #ffffff;
// }

// .google-map {
//   border-radius: 100%;
//   width: 300px;
//   height: 300px;
//   overflow: hidden;
//   margin: 0 auto;
// }

// .google-map iframe {
//   border: 0;
//   width: 300px;
//   height: 300px;
// }

// #contact .form-control {
//   border: 0;
//   border-radius: 5px;
//   box-shadow: none;
//   color: rgba(20, 20, 20, 0.5);
//   font-size: 14px;
//   font-weight: normal;
//   margin-bottom: 15px;
//   padding-left: 8px;
// }

// #contact input,
// #contact select {
//   height: 55px;
// }

// #contact select {
//   color: rgba(20, 20, 20, 0.5);
// }

// #contact input[type='submit'] {
//   background: #ce3232;
//   border-radius: 100px;
//   color: #ffffff;
//   font-weight: 500;
// }



/*---------------------------------------
     FOOTER              
  -----------------------------------------*/

footer {
  background: #000000;
  /* Chrome 10-25, Safari 5.1-6 */
  background-image: linear-gradient(to bottom, #1b1b1b, #000000);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  position: relative;
  padding-top: 80px;
  padding-bottom: 0px;
  height: 600px;
}

footer h2 {
  color: #ffffff;
}

.footer-link {
  margin: 0;
  padding: 0;
}

.footer-link li {
  display: block;
  list-style: none;
  margin: 5px 10px 5px 0;
}

footer p,
footer span,
.footer-link li a {
  color: #d9d9d9;
}

.copyright-text p,
.footer-bottom .phone-contact p {
  font-size: 14px;
}

.footer-info p {
  margin-right: 4em;
}

.footer-bottom {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: 5em;
  padding-top: 3em;
}

.footer-bottom .phone-contact,
.footer-bottom .social-icon {
  display: inline-block;
  vertical-align: top;
}

.footer-bottom .phone-contact {
  margin-right: 40px;
}

.footer-bottom .phone-contact span {
  font-weight: 500;
  display: inline-block;
  margin-left: 10px;
}


/*---------------------------------------
     SOCIAL ICON              
  -----------------------------------------*/

.social-icon {
  position: relative;
  padding: 0;
  margin: 0;
}

.social-icon li {
  display: inline-block;
  list-style: none;
}

.social-icon li a {
  border-radius: 100px;
  color: #d9d9d9;
  font-size: 15px;
  text-decoration: none;
  position: relative;
  margin: 5px 10px;
}

.social-icon li a:hover {
  color: #ce3232;
}


/*---------------------------------------
      MODAL FORM             
  -----------------------------------------*/

@media (min-width: 992px) {
  .modal-dialog {
    width: 420px;
  }
}

.modal-open .modal {
  padding-left: 0 !important;
}

.modal-dialog .modal-content {
  background: #000000;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #434343, #000000);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #434343, #000000);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  border: none;
  border-radius: 2px;
  text-align: center;
  position: relative;
  padding: 2em;
}

.modal-header,
.modal-footer {
  border-bottom: 0;
  padding: 0;
}

.modal-dialog .modal-body {
  padding: 0;
}

.modal-dialog .modal-body a {
  color: #d9d9d9;
}

.modal .close {
  color: #999;
  font-size: 40px;
  font-weight: 300;
  text-shadow: none;
  opacity: 1;
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 100;
  outline: none;
}

.modal-dialog .modal-title {
  margin-bottom: 20px;
}

.modal-dialog .modal-title h2 {
  color: #ffffff;
}

.modal-dialog .nav-tabs {
  display: inline-block;
}

.modal-dialog .nav-tabs>li>a {
  border: 0;
  color: #999999;
  margin-right: 0;
}

.modal-dialog .nav-tabs>li.active>a,
.modal-dialog .nav-tabs>li.active>a:hover,
.modal-dialog .nav-tabs>li.active>a:focus,
.modal-dialog .nav>li>a:focus,
.modal-dialog .nav>li>a:hover {
  color: #ffffff;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #ce3232;
}

.modal-dialog .tab-content {
  padding-top: 20px;
}

.modal-dialog .form .form-control {
  border-radius: 5px;
  border: 1px solid #595959;
  background: transparent;
  box-shadow: none;
  margin: 15px 0 15px 0;
  height: 50px;
  color: white;
}

.modal-dialog .form .form-control:hover,
.modal-dialog .form .form-control:focus {
  border-color: #ffffff;
}

.modal-dialog .form input[type="submit"] {
  background: #ce3232;
  border-color: transparent;
  border-radius: 100px;
  color: #ffffff;
  margin-top: 20px;
}


/*---------------------------------------
     RESPONSIVE STYLES              
  -----------------------------------------*/

@media screen and (max-width: 1170px) {

  .custom-navbar .navbar-nav.navbar-nav-first {
    margin-left: inherit;
  }
}

@media screen and (max-width: 991px) {

  h3 {
    font-size: 1.2em;
  }

  p {
    font-size: 13px;
  }

  #home {
    text-align: center;
  }

  .home-info {
    margin-bottom: 3em;
  }

  .custom-navbar .nav li a {
    font-size: 13px;
    padding-right: 11px;
    padding-left: 11px;
  }

  .custom-navbar .section-btn {
    margin-left: 1em;
  }

  .blog-thumb {
    height: inherit;
  }

  .blog-info {
    padding: 45px 25px;
  }

  .media.blog-thumb .media-left {
    display: block;
    width: 100%;
    padding-right: 0;
    overflow: hidden;
  }

  .media.blog-thumb .media-left {
    height: 250px;
  }

  .media.blog-thumb .media-left img {
    position: relative;
    bottom: 10em;
  }

  #work .work-thumb,
  .footer-thumb {
    margin-top: 20px;
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 640px) and (max-width: 767px) {

  #blog-header {
    height: 100vh;
  }

  .media.blog-thumb .media-left {
    height: 550px;
  }

  .media.blog-thumb .media-left img {
    position: relative;
    bottom: 10em;
  }

}

@media screen and (max-width: 767px) {

  .custom-navbar {
    background: #ffffff;
    -webkit-box-shadow: 0 1px 30px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 1px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 30px rgba(0, 0, 0, 0.1);
    padding: 10px 0;
  }

  .custom-navbar .nav li a {
    line-height: normal;
    padding: 5px;
  }

  .counselling-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-bottom: 50px;
    /* row-gap: 20px; */
  }

  .counselling-item {
    box-shadow: none;
    border: 1px solid #ddd;
    width: 70vw;
    margin-bottom: 10px;
  }


  .custom-navbar .navbar-brand,
  .top-nav-collapse .navbar-brand {
    color: #252525;
    font-weight: 600;
  }

  .custom-navbar .nav li a,
  .top-nav-collapse .nav li a {
    color: #575757;
  }

  .custom-navbar .navbar-nav.navbar-right li {
    display: inline-block;
  }

  .custom-navbar .section-btn {
    display: block !important;
    width: 50%;
    margin: 10px auto 10px auto;
    margin-top: 3em;
    padding: 10px;
  }

  .custom-navbar .section-btn a {
    color: #ffffff !important;
  }

  .home-info span {
    margin-top: 20px;
  }

  #courses .about-info:first-child {
    margin-right: 0;
  }

  .about-info {
    margin: 40px 0 40px 0;
  }

  .google-map {
    margin-top: 50px;
  }

  footer {
    text-align: center;
  }

  .footer-info p,
  .footer-bottom .phone-contact {
    margin-right: 0;
  }
}

@media screen and (max-width: 459px) {

  h1 {
    font-size: 3em !important;
  }

  h6 {
    display: none;
  }

  .media.blog-thumb .media-left {
    height: 320px;
  }

  .media.blog-thumb .media-left img {
    position: relative;
    bottom: 6em;
  }

  .topbar {
    display: none !important;
  }

  section {
    padding-top: 15px;
  }

  .mobile-hidden {
    display: none !important;
  }

  .about-section {
    padding: 60px 10px;
  }

  #home {
    padding-top: 1em;

  }
}



.image-text {
  /* background: linear-gradient(to right, #0575E6, #00F260); */
  background-image: var(--gold-image-1);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: content;
  background-size: cover;
  color: transparent;
}

.nav .section-btn a {
  color: white !important
}

.navbar-brand sup {
  font-size: 1em;
  font-weight: 300;
}

.gradient-text {
  background-image: var(--gradient-0);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: content;
  background-size: cover;
  color: transparent;
}

.gold-gradient-text {
  background-image: var(--gold-gradient);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: content;
  background-size: cover;
  background-position: top;
  color: transparent;
  margin-top: 20px !important;
}

.gold-gradient-text div {
  display: initial;
  background-image: var(--gold-gradient-highlight);
  background-position: top;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: content;
}

h6 {
  font-size: 1.4em;
  overflow: hidden;
  padding: 0;
  word-wrap: break-word;
  text-align: left;
}


h1 {
  font-size: 4.0em;
  color: white;
  /* text-transform: uppercase; */
}

.cursor {
  border-right: 1px solid #ddd;
  height: 25px;
  animation: caret 1s steps(1) infinite;

}

@keyframes caret {
  50% {
    border-color: black;
  }
}






::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: #332a2a;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::selection {
  background: #03a4ed;
  color: #fff;
}

::selection {
  color: #818892;
  background: #f9f6f0;
}

/**/
.about-section {
  padding: 60px 150px;
}

section .section-title {
  text-align: center;
  /* color:var(--gradient-0); */
  margin-bottom: 50px;
  text-transform: uppercase;
}

#courses {
  /* background: #000000; */
  /* fallback for old browsers */
  /* background: -webkit-linear-gradient(to right, #434343, #000000); */
  /* Chrome 10-25, Safari 5.1-6 */
  /* background: linear-gradient(to right, #434343, #000000); */
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  position: relative;
  padding-top: 80px;
  padding-bottom: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.course-list {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 20px;
  /* display: flex;
  column-gap: 20px; */
}

.course-item {
  color: #000;
  display: flex;
  margin-bottom: 15px;
}

.course-ic-wrap {
  border: 2px solid #b38a00;
  padding: 3px;
  margin-right: 20px;
  border-radius: 10px;

}

.course-ic {
  height: 120px;
  width: 120px;
  background-color: #1b1b1b;
  background-size: cover;
  border-radius: 5px;
  background-position: center;
}

.care-giver-bg {
  background-image: url(./assets/images/care-giver.jpg);
}

.young-couple-bg {
  background-image: url(./assets/images/young-couple.jpg);
}

.course-title {
  font-weight: 100;
  /* text-transform: uppercase; */
  font-size: 20px;
  color: #b38a00;
  margin-bottom: 5px;
  /* max-width: 350px; */
}

.course-mode {
  margin-bottom: 15px;
}

.section-illustration {
  height: 350px;
  width: 350px;
  background-size: cover;
  margin-right: 60px;
}

.study-illustration {
  background-image: url(./assets/images/study-image.png);
}

.therapy-illustration {
  background-image: url(./assets/images/therapy-image.png);
  width: 537px !important;
}

.wording-segment {
  position: relative;
  padding-top: 60px;
  padding-bottom: 60px;
  color: white;
  /* background: linear-gradient(45deg, rgb(27, 27, 27), rgb(8, 7, 7)); */
  background-image: url(./assets/images/african-bg2.jpg);
  /* background-size: cover; */
  max-width: 1200px;
  border-radius: 100px;
  margin: auto;
  padding-left: 50px;
  padding-right: 50px;
}

.wording-themselves {
  color: white;
  font-size: 40px;
  font-weight: 700;
  text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000, 2px 2px 0 #000;

}



/*  Team Starts */
.member-details img {
  min-height: 170px;
  min-width: 170px;
}

.team-members {
  transform: rotate(-45deg);
  list-style: none;
}

.team-members li>div {
  float: left;
  width: 22%;
}

.team-members li:nth-child(2)>div:first-child {
  margin-left: 20%;
}

.team-members li:last-child>div:first-child {
  margin-left: 150px;
}

.member-details>div {
  background-color: #ddd;
  margin: 5px;
}

.member-details img {
  transform: rotate(45deg) translate(0, 15px) scale(1.2);
  display: block;
  height: 134px;
}

/* hover content - style */
.member-details>div {
  position: relative;
  overflow: hidden;
}

.member-info {
  position: absolute;
  top: 50%;
  transform: rotate(45deg) translate(-12px, 15px);
  left: 0;
  right: 0;
  z-index: 2;
  text-align: center;
}

.member-info h3,
.member-info p {
  margin: 0;
  color: #fff;
  position: relative;
  opacity: 0;
  visibility: hidden;
}

.member-info h3 {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
  top: -100px;
}

.member-info p {
  font-weight: 300;
  font-size: 12px;
  bottom: -150px;
}

.member-details>div:after {
  content: '';
  background-image: linear-gradient(45deg, rgba(228, 82, 69, .8) 50%, transparent 50%);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
}

/* hover content - onhover */
.member-details *,
.member-details>div:after {
  cursor: pointer;
  transition: all .4s ease;
}

.member-details:hover *,
.member-details:hover>div:after {
  opacity: 1;
  visibility: visible;
}

.member-details:hover .member-info h3 {
  top: 0;
}

.member-details:hover .member-info p {
  bottom: 0;
}

/* Team overview */
.team-overview {
  padding-right: 15px;
}

.team-overview h2 {
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 5px;
}

.team-overview>a {
  margin-bottom: 30px;
  display: block;
}

.team-overview>a:before {
  content: '';
  width: 10px;
  height: 2px;
  position: relative;
  top: -3px;
  margin-right: 5px;
  background-color: #ccc;
  display: inline-block;
}


/* For centering elements - optional - Can use table,tablecell instead */
.flex-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flex-center>div:first-child {
  order: 2;
}

/* RESPONSIVE */
@media only screen and (max-width : 992px) {
  .sm-no-flex {
    display: block;
  }

  .sm-no-float {
    float: none !important;
  }

  .sm-text-center {
    text-align: center;
  }
}

@media only screen and (max-width : 399px) {
  .team-members li>div {
    width: 48%;
  }

  #some-element {
    display: none;
  }
}




/* extra */
.copyright {
  text-align: center;
  margin-top: 30px;
  font-size: 1.6rem;
  background-color: #ededed;
}

.copyright a {
  display: inline-block;
  padding: 15px 2px;
}

.copyright a:not(:last-child):after {
  content: "/";
  margin-left: 10px;
}

.toptal {
  color: #204ecf;
}

.upstack {
  color: #008bf7;
}

.upwork {
  color: #37a000;
}

.fiverr {
  color: #1dbf73;
}

.jobs {
  color: magenta;
  text-decoration: underline;
  margin-top: -15px;
}


.learn-more-modal-parent {
  color: white
}

.payment-information-p {
  color: #fff
}

/* .payment-information-bottom{
  color:white
} */
.payment-information-p span {
  color: #FDB931
}

.payment-information-bottom span {
  color: #FDB931
}

.home-video {
  height: 400px;
  background-size: cover;
  background-image: url(./assets/images/montage-thumb.png);
  background-position: center;
  color: white;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.play-but-wrap {
  width: 60px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #b30c00;
  border-radius: 10px;
  transition: .5s;
  cursor: pointer;
  box-shadow: 0 1px 30px rgba(0, 0, 0, 0.1);
}

.play-but-wrap:hover {
  background-color: #ff8500;
}

.watch-more-but {
  position: absolute;
  top: 10px;
  left: 10px;
  color: white;
  background-color: #1a0e02;
  padding: 4px 10px;
  cursor: pointer;
  border-radius: 4px;
  text-transform: capitalize;
  box-shadow: 0 1px 30px rgba(0, 0, 0, 0.1);
}

.watch-more-but :hover {
  color: yellow !important;
}

.video-modal {
  background-color: rgba(0, 0, 0, 0.616);
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  z-index: 50000;
  display: flex;
  justify-content: center;
  align-items: center;
}

/**/
.the-members {
  margin-top: 20px;
  min-height: 400px;
}

.hex-row {
  display: flex;
  column-gap: 30px;
  justify-content: center;
  align-items: center;
}

.hexagon-wrap {
  filter: drop-shadow(-1px 3px 3px rgba(50, 50, 0, 0.196));
}

.hexagon {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 150px;
  height: 180px;
  background-color: #d29403;
  cursor: pointer;
  background-position: center;
  background-size: cover;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

.hexagon .hex-content {
  background: var(--africa-gradient);
  height: 0px;
  width: 100%;
  color: white;
  opacity: .9;
  transition: .3s;
}

.hexagon .username {
  font-weight: 700;
  font-size: 14px;
  text-decoration: none !important
}

.hexagon .view-more {
  text-decoration: underline;
  font-size: 12px;
}

.hexagon:hover .hex-content {
  padding-top: 6px;
  height: 71px;
}

.who-we {
  width: 700px
}

@media only screen and (max-width : 550px) {
  .hex-row {
    display: block;
  }

  .hexagon {

    width: 70vw;
    height: 70vw;
    clip-path: unset;
    margin-bottom: 10px;
  }

  .team-members li {
    text-align: center;
  }

  .team-members li>div {
    float: none;
    display: inline-block;
    width: 30%;
    margin: 0 !important;
  }

  .team-members {
    transform: rotate(0);
  }

  .member-details img {
    transform: rotate(0) translate(0, 0);
  }

  .team-overview {
    padding: 15px;
  }

  .course-item {
    flex-direction: column;
  }

  .study-illustration {
    display: none;
  }

  .course-ic-wrap {
    margin-right: 0;
    border: 0;
  }

  .course-ic {
    margin: auto;
    height: 220px;
    width: 220px;
  }

  .gold-gradient-text {
    background-image: var(--gold-gradient-linear);
  }

  .course-title,
  .course-duration,
  .course-mode,
  .course-but {
    text-align: center;

  }

  .course-item-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .team-members {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .member-info {
    transform: rotate(0);
    padding-top: 20px;
  }

  .member-details>div:after {
    background-image: linear-gradient(0, rgba(228, 82, 69, .8) 50%, transparent 50%);
  }

  #courses {
    padding: 20px
  }

  .wording-segment {
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .wording-themselves {
    color: white;
    font-size: 20px;
    text-shadow: none;
    /* -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000; */
  }

  footer {
    height: 1000px;
  }

  #some-element {
    display: none;
  }

  .home-video {
    height: 170px
  }

  .who-we {
    width: 70vw;
  }
}

.lds-spinner {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: auto;
}

.lds-spinner div {
  transform-origin: 40px 40px;
  animation: spinner-animation 1.2s linear infinite;
}

.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #fff;
}

.spinner-orange div:after {
  background: #ff8500 !important;
}

.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes spinner-animation {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.yt-modal {
  height: 100vh;
  width: 100vw;
  background-color: #000000c1;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 300000;
  display: flex;
  justify-content: center;
  align-items: center;

  iframe {
    border: 0;
  }
}

.message-sent-parent {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .illustration {
    height: 210px;
    width: 230px;
    background-image: url(./assets/illustration/message_sent.svg);
    background-size: contain;
    background-repeat: no-repeat;
  }

  h2 {
    font-family: Montserrat !important;
    font-weight: 700;
    font-size: 35px;
  }

  p {
    width: 300px;
    text-align: center;
  }
}

.messages-parent {
  display: flex;
  justify-content: center;
  align-items: center;

  .message-wrap {
    border: 1px solid #eee;
    background-color: white;
    text-align: left;

    .thread {
      padding: 10px
    }
    .mess-time{
      font-size: 12px;
      margin-top: 10px;
      color: #aaa;
    }
  }

  .message {
    display: flex;
    width: 1000px;
    padding: 20px;
    background-color: #eee;

    div {
      flex: 1;
      text-align: left;
    }

    .time {
      width: 100px;
      flex: .5;
    }
  }
}